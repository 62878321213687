import React, { createContext, useContext } from 'react'
import useFetchedData from 'hooks/useFetchedData'
import { useCeremony } from './CermonyContext'
import * as urls from 'api/urls'

type Ceremony = ceremonies.Ceremony | null
interface DataState {
  isLoading: boolean
  data?: Ceremony
  setData: React.Dispatch<React.SetStateAction<Ceremony>>
  reload: base.BasicFunc
}

const DataContext = createContext<DataState>({
  isLoading: false,
  setData: () => { },
  reload: () => { }
})

const DataProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [ceremonyID] = useCeremony()
  const url = ceremonyID ? `${urls.ceremonies}/${ceremonyID}` : ''
  const state = useFetchedData<ceremonies.Ceremony>(url, 'data')
  return (
    <DataContext.Provider value={state}>
      {children}
    </DataContext.Provider>
  )
}

export const useData = () => {
  const context = useContext(DataContext)
  if (!context) {
    throw new Error('useData must be used within a DataProvider')
  }
  return context
}

export default DataProvider