import { createContext, useContext, ReactNode } from 'react'
import useStorage from 'hooks/useStorage'

type CeremonyContextProps = [
  ceremonyID: string | null,
  setCeremonyID: (id: string) => void
]

const CEREMONY_SESSION = true
const CEREMONY_KEY = 'ceremony_id'

const CeremonyContext = createContext<CeremonyContextProps | undefined>(undefined)

export const CeremonyProvider = ({ children }: { children: ReactNode }) => {
  const [ceremonyID, setCeremonyID] = useStorage<string | null>(null, CEREMONY_KEY, CEREMONY_SESSION)

  return (
    <CeremonyContext.Provider value={[ceremonyID, setCeremonyID]}>
      {children}
    </CeremonyContext.Provider>
  )
}

export const useCeremony = () => {
  const context = useContext(CeremonyContext)
  if (!context) {
    throw new Error('useCeremony must be used within a CeremonyProvider')
  }
  return context
}
