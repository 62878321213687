import { useNavigate, useParams } from 'react-router-dom'
import { useCeremony } from 'contexts/CermonyContext'
import { useData } from 'contexts/DataContext'
import { useEffect, useState } from 'react'
import Loader from 'components/Loader'
import ModalLink from './ModalLink'
import './index.scss'

const Dashboard = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [, setCeremonyID] = useCeremony()
  const { isLoading, data } = useData()
  const [isLinkModal, setLinkModal] = useState<boolean>(false)

  useEffect(() => {
    if (id) {
      setCeremonyID(id)
    } else {
      navigate('/notfound?error=badlink')
    }

    if (data && data.theme_color) {
      document.documentElement.style.setProperty('--primary-color', data.theme_color);
    }

  }, [id, setCeremonyID, navigate, data])

  return (
    <>
      {isLinkModal && data && <ModalLink
        name={data.name}
        img={data?.code_url}
        code={data?.code}
        onClose={() => setLinkModal(false)}
      />}
      
      {isLoading
        ? <Loader type='full' />
        : <div className="dashboard">
          <div className="dashboard__main">
            <div className="title">
              <h1>{data?.name}</h1>
            </div>

            <div className="content">

              <div className="btn btn--big btn--white" onClick={() => navigate('/gallery')}>
                <i className="icon s48 icon--gallery"></i>
                <span>Galeria</span>
              </div>

              <div className="btn btn--big btn--white" onClick={() => navigate('/menu')}>
                <i className="icon s48 icon--menu"></i>
                <span>Menu</span>
              </div>

              <div className="btn btn--big btn--white" onClick={() => navigate('/schedule')}>
                <i className="icon s48 icon--schedule"></i>
                <span>Harmonogram</span>
              </div>

              <div className="btn btn--big btn--primary" onClick={() => navigate('/add')}>
                <i className="icon s48 icon--upload"></i>
                <span>Dodaj zdjęcia</span>
              </div>

            </div>

            <div className="showMore"
              onClick={() => {
                const moreSection = document.getElementById('more');
                moreSection?.scrollIntoView({ behavior: 'smooth' });
              }}>
              <span>Zobacz więcej</span>
              <i className="icon icon--double-arrow-down"></i>
            </div>

          </div>

          <div id='more' className="dashboard__more">
            <div className="card">
              <i className="icon s32 icon--location"></i>
              <h2>{data?.place_name}</h2>
              <p>{data?.place_address}</p>
            </div>
            <div className="card">
              <i className="icon s32 icon--calendar"></i>
              <h2>{data?.start_date_readable}</h2>
              <p>Rozpoczęcie imprezy o {data?.start_time}</p>
            </div>
            {data?.game_url && <div className="card" onClick={() => window.open("https://sereczyn.pl/impreza/", "_blank")}>
              <i className="icon s32 icon--play"></i>
              <h2>Kliknij, aby zagrać w grę</h2>
            </div>}
            <span>Informacja dla gości</span>
            <div className="card">
              <p>{data?.description}</p>
              <span>~ SnapWish</span>
            </div>
            <div className="card" onClick={() => setLinkModal(true)}>
              <i className="icon s32 icon--share"></i>
              <h2>Link do wydarzenia</h2>
              <p>Kliknij, aby udostępnić link lub zapisać sobie go na później</p>
            </div>
          </div>

          {/* <div className="dashboard__footer">
        <p>W trakcie imprezy i do dwóch dni po możecie
          wrzucić tutaj swoje zdjęcia i filmy!</p>
        <span>snapwish.pl</span>
      </div> */}

        </div>}
    </>)
}

export default Dashboard