import { useNavigate } from 'react-router-dom'
import { POST } from 'api/request'
import { useState } from 'react'
import * as url from 'api/urls'
import './index.scss'

const Home = () => {
  const [code, setCode] = useState<string>('')
  const [codeError, setCodeError] = useState<string>('')

  const navigate = useNavigate()

  const validateCode = () => {
    let errors = false

    if (!code.trim()) {
      setCodeError('Kod jest wymagany')
      errors = true
    } else if (code.length < 3 || code.length > 20) {
      setCodeError('Kod musi posiadać między 3 a 20 znaków')
      errors = true
    } else if (!/^[a-zA-Z0-9]+$/.test(code)) {
      setCodeError('Kod może zawierać tylko litery i cyfry')
      errors = true
    } else {
      setCodeError('')
    }

    return !errors
  }

  const handleSendCode = async () => {
    if (!validateCode()) {
      return
    }

    try {
      const response = await POST(url.scanqr, { body: { code: code } })

      if (response.ok) {
        const ceremonyID = response.data.data.ceremony_id
        navigate(`/dashboard/${ceremonyID}`)
      } else {
        setCodeError('Wystąpił błąd')
        throw new Error('Failed to fetch qr')
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="home">
      <div className="home__content">
        <button className="btn btn--primary" onClick={() => navigate('/qrscanner')}>
          <i className="icon icon--qr"></i>
          Kliknij, aby zeskanować kod QR
        </button>
        <div className="or">
          Lub podaj kod imprezy
        </div>
        <div className={`input ${codeError ? 'error' : ''}`}>
          <label>{codeError ? codeError : 'Wpisz kod imprezy'}</label>
          <input
            type="text"
            placeholder='Kod imprezy'
            value={code}
            onChange={(e) => {
              setCode(e.target.value)
              if (codeError) {
                validateCode()
              }
            }} />
          <div className="btn" onClick={() => handleSendCode()}>
            <i className="icon icon--send"></i>
          </div>
        </div>
      </div>
    </div>)
}

export default Home