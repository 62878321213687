import { BrowserRouter as Router } from 'react-router-dom'
import { CeremonyProvider } from 'contexts/CermonyContext'
import { ModalProvider } from 'contexts/ModalContext'
import { AlertProvider } from 'contexts/AlertContext'
import ReactDOM from 'react-dom/client'
import Alert from 'components/Alert'
import App from './App'
import './index.scss'
import DataProvider from 'contexts/DataContext'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  // <React.StrictMode>
  <AlertProvider>
    <CeremonyProvider>
      <DataProvider>
        <Router>
          <Alert />
          <ModalProvider>
            <App />
          </ModalProvider>
        </Router>
      </DataProvider>
    </CeremonyProvider>
  </AlertProvider>
  // </React.StrictMode>
)
