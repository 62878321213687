import { updateStatusBarColor } from 'utils/updateStatusBarColor'
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import VideoWrapper from './VideoWrapper'
import ComentsModal from './ComentsModal'
import ImageWrapper from './ImageWrapper'
import { POST } from 'api/request'
import SwiperCore from 'swiper'
import * as url from 'api/urls'

import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import DeleteModal from './DeleteModal'

interface SwiperGalleryModalProps {
  initialSlide: number
  data: ceremonies.Gallery[]
  onClose: () => void
  onReload: () => void
  setData: (data: ceremonies.Gallery[]) => void
}

const style = {
  '--swiper-navigation-color': '#ffffff',
  '--swiper-pagination-color': '#ffffff',
} as React.CSSProperties

const SwiperGalleryModal: React.FC<SwiperGalleryModalProps> = ({ data, initialSlide, onClose, setData, onReload }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(initialSlide)
  const [isCommentView, setCommentView] = useState<boolean>(false)
  const [isDeleteModal, setDeleteModal] = useState<boolean>(false)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const showReadMore = data[currentSlideIndex].wishes && data[currentSlideIndex].wishes.length > 100
  const navigate = useNavigate()

  const handleReadMore = () => {
    setIsExpanded((prev) => !prev)
  }

  const handleSlideChange = (swiper: SwiperCore) => {
    const index = swiper.realIndex
    setCurrentSlideIndex(index)
  }

  const updateGallery = (newGallery: ceremonies.Gallery) => {
    setData(data.map((d, i) => i === currentSlideIndex ? newGallery : d))
  }

  useEffect(() => {
    updateStatusBarColor('#000000')
    window.history.pushState(null, '', window.location.pathname)
  }, [])

  useEffect(() => {
    const handlePopState = () => {
      if (!isCommentView || !isDeleteModal) onClose()
    }

    window.addEventListener('popstate', handlePopState)
    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCommentView, isDeleteModal])

  const sendLike = async () => {
    try {
      const response = await POST(url.uploadComments(data[currentSlideIndex].id), {
        body: {
          is_public: 1,
          is_like: 1,
          text: null,
          signature: null
        }
      })
      if (response.ok && response.data?.data) {
        updateGallery(response.data.data)
      } else {
        alert('W trakcie wysyłania wystąpił błąd')
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      {isDeleteModal && <DeleteModal id={data[currentSlideIndex].id} onClose={() => setDeleteModal(false)} onReload={onReload} />}
      {isCommentView && <ComentsModal
        data={data[currentSlideIndex]}
        onClose={() => setCommentView(false)}
        setData={updateGallery}
      />}

      <div className='galleryPopup'>
        <div className='galleryPopup__content'>
          <div className='galleryPopup__content__head'>
            <div className="iconWrap"><i className='icon icon--close s20' onClick={() => navigate(-1)}></i></div>
            <div className='right'>
              {data[currentSlideIndex].can_delete && <span className='deleteBtn' onClick={() => setDeleteModal(true)}>Usuń</span>}
              {data[currentSlideIndex].downloadUrl && <span className='deleteBtn'>
                <a
                  href={data[currentSlideIndex].downloadUrl}
                  download
                  className="download-link"
                >
                  <i className="icon icon--download"></i> Pobierz
                </a></span>}
            </div>
          </div>
          <div className='galleryPopup__content__body column'>
            {data && <>
              <Swiper
                loop
                navigation
                style={style}
                spaceBetween={10}
                initialSlide={initialSlide}
                onSlideChangeTransitionEnd={handleSlideChange}
                modules={[FreeMode, Navigation, Thumbs]}
                className='gallery__swiper'>
                {data.map((item, idx) => {
                  const difference = currentSlideIndex - idx
                  return <SwiperSlide key={item.id}>
                    {(difference > -2 && difference < 2) && (item.isVideo
                      ? <VideoWrapper {...item} />
                      : <ImageWrapper {...item} />)}
                  </SwiperSlide>
                })}
              </Swiper>
            </>}
          </div>
          <div className="galleryPopup__content__footer">
            {data[currentSlideIndex].wishes !== null && (
              <>
                <div className={`signature ${isExpanded ? 'expanded' : ''}`}>{data[currentSlideIndex].signature}</div>
                <div className="wishes">
                  <div className={`wishesText ${isExpanded ? 'expanded' : ''}`}>
                    {data[currentSlideIndex].wishes}
                  </div>
                  {showReadMore && !isExpanded
                    ? (
                      <div className="readMore" onClick={handleReadMore}>
                        czytaj więcej
                      </div>
                    )
                    : showReadMore && (
                      <div className="readMore" onClick={handleReadMore}>
                        zwiń
                      </div>
                    )}
                </div>
              </>
            )}
            <div className="commentsBar">
              <div className="like"
                onClick={() => {
                  data[currentSlideIndex].has_device_liked ? console.log('Nie mozna odlubić :(') : sendLike()
                }}>
                <i className={`icon s18 icon--${data[currentSlideIndex].has_device_liked ? 'like-fill' : 'like'}`}></i>
                <span>{data[currentSlideIndex].has_device_liked ? `Polubiono` : `Polub`} {'(' + data[currentSlideIndex].likes_count + ')'}</span>
              </div>
              <div className="comment" onClick={() => setCommentView(true)}>
                <i className={`icon s18 icon--comment`}></i>
                <span>{'Komentarze (' + data[currentSlideIndex].comments_count + ')'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SwiperGalleryModal