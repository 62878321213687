import { useEffect, useState } from 'react'
import { GET, POST } from 'api/request'

type Method = 'POST' | 'GET'

const useFetchedData = <T,>(
  url: string,
  destructKeys: string = '',
  method: Method = 'GET',
  body?: object
) => {
  const [data, setData] = useState<null | T>(null)
  const [isLoading, setLoading] = useState(false)

  const fetchDetails = async (_url: string, _destructKeys: string, _method: Method, _body?: object) => {
    setLoading(true)
    try {
      const request = _method === 'POST' ? POST : GET
      const response = await request(_url, { body: _body })
      if (response.ok) {
        const d = _destructKeys
          ? _destructKeys.split('.').reduce((r, k) => r[k] || r, response.data)
          : response.data
        setData(d)
      } else {
        throw new Error(response.data.message)
      }
      setLoading(false)

    } catch (error) {
      setLoading(false)
      console.error('Fetch failed: ', error)
    }
  }

  useEffect(() => {
    if (url) fetchDetails(url, destructKeys, method, body)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destructKeys, method, url, body])

  return {
    data,
    isLoading,
    setData,
    reload: async () => {
      fetchDetails(url, destructKeys, method)
    }
  }
}

export default useFetchedData