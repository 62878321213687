import { useData } from 'contexts/DataContext'
import Loader from 'components/Loader'
import TopBar from 'components/Topbar'
import { useEffect, useState } from 'react'
import './index.scss'

const Schedule = () => {
  const { isLoading, data } = useData()
  const [activeIndices, setActiveIndices] = useState<number[]>([])

  useEffect(() => {
    const updatePastEvents = () => {
      if (data && data.events) {
        const currentTime = new Date(); // Bieżący czas
        const pastEventIndices = data.events
          .map((event, index) => {
            // Zamiana stringów na liczby
            const year = parseInt(event.date.year, 10);
            const month = parseInt(event.date.month, 10) - 1; // Miesiące w Date są 0-indexowane
            const day = parseInt(event.date.day, 10);
            const hour = parseInt(event.time.hour, 10);
            const minute = parseInt(event.time.minute, 10);

            const eventDate = new Date(year, month, day, hour, minute);

            // Porównaj wydarzenie z bieżącym czasem
            return eventDate < currentTime ? index : -1;
          })
          .filter(index => index !== -1); // Filtruj -1 (nie przeszłe wydarzenia)

        setActiveIndices(pastEventIndices); // Zaktualizuj aktywne indeksy
      }
    };

    // Uruchom aktualizację wydarzeń od razu po załadowaniu danych
    updatePastEvents();

    // Ustaw interwał, aby aktualizować co 30 sekund
    const intervalId = setInterval(updatePastEvents, 30000);

    // Wyczyść interwał po zakończeniu komponentu
    return () => clearInterval(intervalId);
  }, [data]); // Tylko jeśli data się zmieni
  return (
    isLoading
      ? <Loader type='full' />
      : <div className="schedule">
        <TopBar title="Harmonogram" isSticky theme="dark" />
        <div className="schedule__content">
          {data?.events
            ? data?.events.map((event, index) => (
              <div
                key={index}
                className={`item ${activeIndices.includes(index) ? 'active' : ''}`}
              >
                <span>
                  {event.time.hour.toString().padStart(2, '0')}:
                  {event.time.minute.toString().padStart(2, '0')}
                </span>
                <div className="number">
                  <div className="wrap">{index + 1}</div>
                </div>
                <h2>{event.title}</h2>
              </div>
            ))
            : <div className="empty">Brak Harmonogramu</div>
          }
        </div>
      </div>
  )
}

export default Schedule
