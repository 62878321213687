import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
interface VideoStyleProps {
  width: string
  height: string
  maxWidth?: string
}

const VideoWrapper: React.FC<ceremonies.Gallery> = ({ url, cdnStatus }) => {
  const [videoStyle, setVideoStyle] = useState<VideoStyleProps | undefined>(undefined)
  const [isVideoLoading, setVideoLoading] = useState<boolean>(true)

  useEffect(() => {
    const videoElement = document.createElement('video')

    videoElement.src = url
    videoElement.addEventListener('loadedmetadata', () => {
      const { videoWidth, videoHeight } = videoElement
      const aspectRatio = videoWidth / videoHeight

      if (aspectRatio < 1) {
        setVideoStyle({
          width: 'auto',
          height: 'calc(calc(100 * var(--vh, 1vh)) - 1em - 70px - 36px)',
          maxWidth: '100%'
        })
      } else {
        setVideoStyle({
          width: '100%',
          height: 'auto'
        })
      }
    })
  }, [url])

  return (
    cdnStatus === 'SUCCESS' ? (
      <>
        <div className={`loader ${isVideoLoading && 'action'}`}>
          <i className='icon icon--pending'></i>
          <span> Ładowanie...</span>
        </div>
        <div className="video-wrapper" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ReactPlayer
            onReady={() => setVideoLoading(false)}
            controls
            playsinline
            loop={false}
            width={videoStyle ? videoStyle.width : '100%'}
            height={'100%'}
            url={url}
            style={{ maxHeight: 'calc(calc(100 * var(--vh, 1vh)) - 1em - 70px - 36px)' }}
          />
        </div>
      </>
    ) : cdnStatus === 'ERROR' ? (
      <div className='text'>Do ponowienia</div>
    ) : cdnStatus === 'PENDING' || cdnStatus === 'INIT' ? (
      <div className='text'>Ładowanie...</div>
    ) : null
  )
}

export default VideoWrapper