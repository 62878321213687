
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Loader from 'components/Loader'
import ErrorModal from './ErrorModal'
import { POST } from 'api/request'
import * as url from 'api/urls'
import './index.scss'

const QrReader = () => {
  const [modal, setModal] = useState<boolean>(false)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchQR = async () => {
      const hash = `${window.location.origin}${pathname}`

      try {
        const response = await POST(url.scanqr, { body: { code: hash } })

        if (response.ok) {
          const ceremonyID = response.data.data.ceremony_id
          navigate(`/dashboard/${ceremonyID}`)
        } else {
          setModal(true)
          throw new Error('Failed to fetch qr')
        }
      } catch (err) {
        console.error(err)
      }
    }
    fetchQR()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {modal && <ErrorModal />}
      <Loader type="full" />
    </>)
}

export default QrReader