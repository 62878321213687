import { useLocation, useNavigate } from "react-router-dom"
import './index.scss'

const Notfound = () => {
  const { search } = useLocation()
  const navigate = useNavigate()

  console.log(search)
  //wynik dla search ?error=test1
  return (
    <div className="notfound">
      <div className="notfound__content">
        <h1>Nie znaleziono :(</h1>
        <div className="body">
          <p>{
            search === '?error=badurl'
              ? 'To nie jest nasz kod QR'
              : search === '?error=noresult'
                ? 'Kod QR nie zwrócił rezultatu'
                : search === '?error=badlink'
                  ? 'Niestety ten link jest niepoprawny. Złe ID imprezy'
                  : 'basetext'
          }</p>
        </div>
        <div className="btn btn--primary" onClick={() => navigate('/')}>
          Wróć do strony głównej
        </div>
      </div>
    </div>
  )
}

export default Notfound