import { useData } from 'contexts/DataContext'
import Loader from 'components/Loader'
import TopBar from 'components/Topbar'
import './index.scss'

const Menu = () => {
  const { isLoading, data } = useData()

  return (
    isLoading
      ? <Loader type='full' />
      : <div className="menu">
        <TopBar title="Menu" isSticky theme="dark" />
        <div className="menu__content">
          {data?.menu
            ? data?.menu.map((item) =>
              <div key={item.id} className="item">
                <div className="item__head">
                  <h2>{item.name}</h2>
                  <span>{item.time}</span>
                </div>
                <div className="item__body">
                  <p>{item.description}</p>
                </div>
              </div>)
            : <div className='empty'>Brak Menu</div>}
        </div>
      </div>
  )
}

export default Menu