import { useCeremony } from 'contexts/CermonyContext'
import { useNavigate } from 'react-router-dom'
import { useData } from 'contexts/DataContext'
import Modal from 'components/Modal/Modal'
import React, { useState } from 'react'
import { POST } from 'api/request'
import * as url from 'api/urls'

interface ModalMultipleMediaProps {
  media: File[]
  setMedia: (m: File[]) => void
  onClose: () => void
}

const ModalMultipleMedia: React.FC<ModalMultipleMediaProps> = ({ media, setMedia, onClose }) => {
  const [ceremonyID] = useCeremony()
  const [isCheck, setCheck] = useState<boolean>(true)
  const [isSending, setSending] = useState<boolean>(false)
  const [isInfoVisible, setInfoVisible] = useState<boolean>(false)
  const [mediaStatuses, setMediaStatuses] = useState(media.map(() => ""))

  const { reload } = useData()
  const navigate = useNavigate()
  const handleMediaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setMedia([...media, ...Array.from(event.target.files)])
    }
  }

  const handleRemoveMedia = (index: number) => {
    setMedia(media.filter((_, i) => i !== index))
  }

  const handleSubmit = async () => {
    if (media.length === 0) return
    setSending(true)
    setMediaStatuses(media.map(() => "pending"))

    for (let i = 0; i < media.length; i++) {
      try {
        setMediaStatuses((prevStatuses) => prevStatuses.map((status, index) => (index === i ? "uploading" : status)))

        const formData = new FormData()
        formData.append(`media[${i}]`, media[i])
        formData.append('is_public', isCheck ? '1' : '0')

        const response = await POST(url.uploadMedia(ceremonyID), {
          form: formData,
        })

        const responseData = response.data[0];
        if (response.ok && responseData.result) {
          setMediaStatuses((prevStatuses) =>
            prevStatuses.map((status, index) => (index === i ? "uploaded" : status))
          );
        } else if (response.ok && responseData.reason === 'duplicate') {
          setMediaStatuses((prevStatuses) =>
            prevStatuses.map((status, index) => (index === i ? "duplicate" : status))
          );
        }
        else {
          setMediaStatuses((prevStatuses) =>
            prevStatuses.map((status, index) => (index === i ? "failed" : status))
          );
        }
      } catch (err) {
        console.error(err);
        setMediaStatuses((prevStatuses) =>
          prevStatuses.map((status, index) => (index === i ? "failed" : status))
        );
      }
    }

    reload()
    setSending(false);
    setInfoVisible(true);
  }

  const calculateProgress = () => {
    const uploadedCount = mediaStatuses.filter(status => status === "uploaded").length
    return Math.round((uploadedCount / media.length) * 100)
  }

  const checkStatusSummary = () => {
    const allFailed = mediaStatuses.every(status => status === "failed")
    const someFailed = mediaStatuses.some(status => status === "failed")
    const hasDuplicate = mediaStatuses.some(status => status === "duplicate")
    return { allFailed, someFailed, hasDuplicate }
  }

  const { allFailed, someFailed, hasDuplicate } = checkStatusSummary();

  return (
    <Modal onClose={onClose}
      title={isInfoVisible
        ? allFailed
          ? 'Wystąpił błąd'
          : 'Wysłano!'
        : isSending
          ? `${calculateProgress()}% przesłano`
          : 'Prześlij zdjęcia i filmy'}    >
      {isInfoVisible
        ? <div className='modal__multipleMedia'>
          <p className='sendInfo'>
            {allFailed
              ? 'Wystąpił błąd, nic nie zostało przesłane'
              : isCheck
                ? someFailed
                  ? 'Nie wszystkie zdjęcia zostały wysłane, możesz podejrzeć zdjęcia w galerii.'
                  : hasDuplicate
                    ? 'Materiały bez duplikatów zostały przesłane i możesz podejrzeć je w galerii'
                    : 'Możesz podejrzeć zdjęcia w galerii.'
                : someFailed
                  ? 'Nie wszystkie zdjęcia zostały wysłane, te które zostały przesłane nie będą widoczne w galerii'
                  : hasDuplicate
                    ? 'Materiały bez duplikatów zostały przesłane i nie będą widoczne w galerii'
                    : 'Materiały zostały przesłane. Nie będą widoczne w galerii.'}
          </p>
          <div className="footer footer--double">
            {isCheck && !allFailed && <div className='btn btn--primary' onClick={() => navigate('/gallery')}>
              Zobacz galerię
            </div>}
            <div
              className='btn btn--border'
              onClick={() => {
                setInfoVisible(false)
                setMedia([])
              }}>
              Dodaj kolejne
            </div>
          </div>
        </div>
        : <div className="modal__multipleMedia">
          <div className={`check ${isCheck && 'active'} ${isSending && 'sending'}`} onClick={() => { !isSending && setCheck(!isCheck) }}>
            <span>Widoczne w galerii dla wszystkich</span>
            <div className="box">
              <i className="icon icon--check"></i>
            </div>
          </div>

          <div className="toSend">
            <span className='desc'>Materiały do wysłania</span>
            <div>
              {media.map((file, index) => {
                const fileURL = URL.createObjectURL(file)
                const status = mediaStatuses[index]
                return (
                  <div key={index}>
                    <div className="iconWrap" onClick={() => handleRemoveMedia(index)}><i className="icon icon--close"></i></div>
                    {file.type.startsWith('image/') ? (
                      <img
                        src={fileURL}
                        alt={`Preview ${index}`}
                        style={{ width: '60px', height: '60px', objectFit: 'cover', marginRight: '5px' }}
                      />
                    ) : (
                      <video
                        src={fileURL}
                        autoPlay
                        muted
                        style={{ width: '60px', height: '60px', objectFit: 'cover', marginRight: '5px' }}
                      />
                    )}
                    <p className={`status ${status === '' || !status ? 'hide' : ''}`}>
                      {status === "uploading" && <div><i className="icon icon--pending"></i><span>Wysyłanie...</span></div>}
                      {status === "pending" && <div><span>W kolejce</span></div>}
                      {status === "uploaded" && <div><i className="icon icon--check s24"></i><span>Wysłano</span></div>}
                      {status === "failed" && <div><i className="icon icon--close"></i><span>Błąd</span></div>}
                      {status === "duplicate" && <div><i className="icon icon--close"></i><span>Duplikat</span></div>}
                    </p>
                  </div>
                )
              })}
              <div className="add" onClick={() => document.getElementById('mediaInput2')?.click()}>
                <i className="icon icon--upload"></i>
                <span>Dodaj więcej</span>
              </div>
              <input
                type="file"
                id="mediaInput2"
                style={{ display: 'none' }}
                onChange={handleMediaChange}
                accept="image/*,video/*"
                multiple
              />
            </div>
          </div>
          <div className="footer">
            <div className={`btn btn--primary ${isSending && 'btn--sending'}`} onClick={isSending ? undefined : handleSubmit}>
              {isSending
                ? 'Wysłanie...'
                : 'Wyślij'
              }
            </div>
          </div>
        </div>}
    </Modal>
  )
}

export default ModalMultipleMedia