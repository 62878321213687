// import { useNavigate } from 'react-router-dom'
import Modal from 'components/Modal/Modal'

const ErrorModal = () => {
  // const navigate = useNavigate()
  return (
    <Modal
      onClose={() => console.log('')}
      title='Wystąpił błąd'
      // secondaryButton='Pomoc'
      // secondaryButtonAction={() => navigate('/pomoc')}
      >
        <p className='info' style={{textAlign: 'center'}}>Wystąpił nieoczekiwany błąd, spróbuj ponownie zeksnować kod lub skontaktuj się z Nami</p>
    </Modal>
  )
}

export default ErrorModal