import { useNavigate } from 'react-router-dom'
import './index.scss'

interface TopbarProps {
  theme: string
  isSticky: boolean
  button?: boolean
  title?: string
}

const TopBar: React.FC<TopbarProps> = ({ theme, isSticky, title, button }) => {
  const navigate = useNavigate()

  return (
    <>
      <div className={`topbar topbar--mobile ${isSticky ? 'sticky' : ''} ${theme}`}>
        <div className="topbar__wrap">
          <div className="back" onClick={() => navigate(-1)}>
            <i className='icon icon--back' ></i>
            <span>Wróć</span>
          </div>

          <h1>{title}</h1>
          {button &&
          
          <div className="button" onClick={() => navigate('/add')}>
            <i className="icon icon--plus"></i>
          </div>
          }
        </div>
      </div>
    </>
  )
}

export default TopBar