import TopBar from 'components/Topbar'
import './index.scss'
import { useState } from 'react'
import ModalMultipleMedia from './ModalMultipleMedia'
import ModalSendWishes from './ModalSendWishes'
import { useData } from 'contexts/DataContext'

const Add = () => {
  const [media, setMedia] = useState<File[]>([])
  const [modal, setModal] = useState<string>('')
  const { data } = useData()

  const handleMediaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setMedia([...media, ...Array.from(event.target.files)])
      setModal('multipleMedia')
    }
  }

  return (
    <>
      {modal === 'multipleMedia' && media.length > 0 && <ModalMultipleMedia media={media} setMedia={setMedia} onClose={() => setModal('')} />}
      {modal === 'wishes' && <ModalSendWishes onClose={() => setModal('')} />}
      <div className="add">
        <TopBar title="Dodawanie" isSticky theme="dark" />
        <div className="add__content">
          <div className="btn btn--secondary"
            onClick={() =>
              media.length > 0
              ? setModal('multipleMedia')
              : document.getElementById('mediaInput')?.click()
            }>
            <i className="icon icon--gallery s48"></i>
            <span>Kliknij, aby dodać wiele zdjęć i filmów</span>
            <input
              type="file"
              id="mediaInput"
              style={{ display: 'none' }}
              onChange={handleMediaChange}
              accept="image/*,video/*"
              multiple
            />
          </div>
          <div className="or">
            <span>lub dodaj zdjęcie z {data?.can_wish ? 'życzeniami' : 'podpisem'}</span>
          </div>
          <div className="btn btn--primary" onClick={() => setModal('wishes')}>
            <i className="icon icon--smile s48"></i>
            <span>Kliknij, aby dodać 1 zdjęcie z {data?.can_wish ? 'życzeniami' : 'podpisem'}</span>
          </div>
        </div>
      </div>
    </>)
}

export default Add