import { loadState, removeItem, saveState } from 'utils/storage'
import { Dispatch, SetStateAction, useState } from 'react'

const useStorage = <T,>(
  initialState: T,
  storageKey: string,
  session = true
) => {
  const [data, setData] = useState<T>(loadState<T>(session, storageKey, initialState))
  const updateData: Dispatch<SetStateAction<T>> = getState => {
    setData(prev => {
      const isFunction = (arg: any): arg is Function => typeof arg === 'function'
      const state = isFunction(getState) ? getState(prev) : getState
      if (state === null || state === undefined) {
        removeItem(session, storageKey)
      } else {
        saveState(state, session, storageKey)
      }
      return state
    })
  }

  return [
    data,
    updateData
  ] as const
}

export default useStorage