export const api = process.env.REACT_APP_API || `https://api.snapwish.pl/v1/`

export const scanqr = `${api}qrscan`
export const ceremonies = `${api}ceremonies`
export const uploadMedia = ceremonyID => `${api}ceremonies/${ceremonyID}/upload`
export const uploadComments = mediaID => `${api}media/${mediaID}/comments`
export const retryVideo = videoID => `${api}media/${videoID}/retry`
export const deletePhoto = mediaID => `${api}media/${mediaID}`

//Do wyrzucenia
export const qrs = `${api}qrs`
export const pins = `${api}pins`
export const config = `${api}config`
export const logout = `${api}logout`
export const qrscan = `${api}qrscan`
export const boards = `${api}boards`
export const orders = `${api}orders`
export const boarddetails = `${api}boarddetails`
export const userUpdate = `${api}user/update`
export const userDelete = `${api}user/delete`
export const analytics = `${api}ajax`
export const checkemail = `${api}checkemail`
export const ordersInfo = `${api}ordersinfo`
export const attachOrders = `${api}attachorders`
export const resetPassword = `${api}password/reset`
export const forgotPassword = `${api}password/forgot`
export const payment = `${api}payment/init`
export const paymentStatus = `${api}payment/status`
export const checkPrice = `${api}check_price`
export const boardslist = `${api}boardslist`
export const savedBoards = `${api}savedBoards`
export const privacy = `${api}shop/privacy`
export const terms = `${api}shop/terms`
export const places = `${api}places`
export const register = `${api}register`
export const findPlace = `${api}find_place`
export const login = `${api}clients/mobile/login`
export const refresh = `${api}clients/mobile/refresh`
export const obituaryPreview = `${api}obituary_preview`
export const lastorderinfo = `${api}lastorderinfo`
export const uploadYt = mediaID => `${api}media/${mediaID}/retry`
export const delivery_points = `${api}couriers/delivery_points`

export const publishMedia = mediaID => `${api}media/${mediaID}/publish`
export const deleteMedia = mediaID => `${api}media/${mediaID}`
export const bookPublish = mediaID => `${api}bookentries/${mediaID}/publish`
export const bookDelete = mediaID => `${api}bookentries/${mediaID}`
export const actionPins = pinID => `${api}pins/${pinID}`
export const saveBoard = boardID => `${api}savedBoards/${boardID}`
export const addBoard = qrID => `${api}qrs/${qrID}/boards`
export const updatedBoard = boardID => `${api}boards/${boardID}`
export const mainImgBoard = boardID => `${api}boards/${boardID}/img`
export const uploadBoard = boardID => `${api}boards/${boardID}/upload`
export const addPost = boardID => `${api}boards/${boardID}/book`
export const action = boardID => `${api}boards/${boardID}/action`
export const addCandle = boardID => `${api}boards/${boardID}/candles`
export const notifications = boardID => `${api}boards/${boardID}/notifications`
export const notificationAction = notificationID => `${api}notifications/${notificationID}/action`

const auth = `${api}auth/`
export const googleCallback = `${auth}google`
export const facebookCallback = `${auth}facebook`
export const appleCallback = `${auth}apple`

export const checkaddress = 'https://api.mmapp.pl/v1/checkaddress'
