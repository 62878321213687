import { useNavigate } from 'react-router-dom'
import { Scanner } from '@yudiel/react-qr-scanner'
import TopBar from 'components/Topbar'
import './index.scss'

interface QrScannerProps {
  setQrData?: (data: QrScan) => void
}

interface QrScan {
  object: string
  id: string
  code: string
  is_owned: boolean
  qrState: 'occupied' | 'free'
  boards: board.Board[]
}

const QrScanner: React.FC<QrScannerProps> = () => {
  const navigate = useNavigate()

  const handleScan = (result: any) => {
    if (result && result[0] && result[0].rawValue) {
      const rawValue = result[0].rawValue
      if (rawValue.includes("app.snapwish.pl/qr")) {
        window.location.href = rawValue
      } else {
        navigate('/notfound?error=badurl')
      }
    } else {
      navigate('/notfound?error=noresult')
    }
  }

  return (
    <div className="qrScanner">
      <TopBar title='Skanowanie...' theme='dark' isSticky={true} />
      <Scanner
        onScan={handleScan}
        styles={{
          video: {
            objectFit: 'cover',
            maxWidth: '1140px',
            margin: '0 auto',
          },
          finderBorder: 55
        }}
      />
      <div className="qrScanner__button">
        <button
          className="btn btn--secondary"
          onClick={() => navigate('/')}>
          Anuluj
        </button>
      </div>
    </div>
  )
}

export default QrScanner