import Modal from 'components/Modal/Modal'

interface ModalLinkProps {
  img: string
  code: string
  name: string
  onClose: () => void
}

const ModalLink: React.FC<ModalLinkProps> = ({ img, code, name, onClose }) => {

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: name,
          text: 'Wejdź i dodaj swoje zdjęcia!',
          url: `https://app.snapwish.pl/qr/${code}`
        })
      } catch (error) {
        console.error('Błąd udostępniania', error)
      }

    } else {
      alert('Niestety ta funkcja nie jest wspierana w Twojej przeglądarce. Skopiuj link z paska adresu i prześlij dalej.')
    }
  }

  return (
    <Modal title='Udostępnij link' onClose={onClose}>
      <div className="modalLink">
        <div className="imgWrap">
          <img src={img} alt="" />
        </div>
        <div className="partyCode">
          <span className='desc'>Kod Imprezy: </span><span>{code}</span>
        </div>
        {/* <span className='or'>Lub udostępnij komuś link</span> */}
        <div className="btn btn--primary" onClick={handleShare}>
          Kliknij i udostępnij link
        </div>
      </div>
    </Modal>
  )
}

export default ModalLink